import React from 'react';

import { ReactComponent as InfoIcon } from '../../../assets/icons/InfoIcon.svg';

import styles from './WidgetTitle.module.scss';

const WidgetTitle = ({ title, description }) => {
  return (
    <div className={styles.container}>
      <span>{title}</span>
      <div className={styles.tooltip}>
        <InfoIcon />
        <p>{description}</p>
      </div>
    </div>
  );
};

export default WidgetTitle;
