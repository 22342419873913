import React from 'react';

import { customTickNumberFormatter } from '../../charts/AreaChart/numberFormatter';
import CustomLineChart from '../../charts/LineChart/LineChart';
import { CustomDot } from '../../charts/LineChart/components/CustomDot';
import CustomLineChartNumberToolTip from '../../charts/LineChart/components/CustomLineChartNumberToolTip';
import classNames from 'classnames';

import commonStyles from '../../../assets/styles/Common.module.scss';
import styles from './TotalViewsChartWidget.module.scss';

const TotalViewsChartWidget = ({ data, loading = false }) => {
  return (
    <div className={classNames(styles.container, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <div className={styles.header}>
            <h1>Total Views</h1>
            <ul>
              <li>
                <div style={{ color: '#1B2559' }} className={styles.dot}></div>
                <span>Total Views</span>
              </li>
              <li>
                <div style={{ color: '#A3AED0' }} className={styles.dot}></div>
                <span>Unique views</span>
              </li>
            </ul>
          </div>
          <CustomLineChart
            height={250}
            data={data}
            CustomDot={CustomDot}
            yCustomTickFormatter={customTickNumberFormatter}
            CustomToolTip={CustomLineChartNumberToolTip}
            xDataKey="month"
            yDataKey2="unique"
            yDataKey="all"
          />
        </>
      ) : null}
    </div>
  );
};

export default TotalViewsChartWidget;
