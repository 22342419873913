import React, { useMemo } from 'react';

import { customTickNumberFormatter } from '../../components/charts/AreaChart/numberFormatter';
import CustomLineChart from '../../components/charts/LineChart/LineChart';
import { CustomDot } from '../../components/charts/LineChart/components/CustomDot';
import CustomLineChartNumberToolTip from '../../components/charts/LineChart/components/CustomLineChartNumberToolTip';
import PeriodSelect from '../../components/periodSelect/PeriodSelect';
import EarningsWidget from '../../components/widgets/earningsWidget/EarningsWidget';
import MilestonesWidget from '../../components/widgets/milestonesWidget/MilestonesWidget';
import TableWidget from '../../components/widgets/tableWidget/TableWidget';
import TabsWidget from '../../components/widgets/tabsWidget/TabsWidget';
import Helpers from '../../utils/Helpers';
import {
  IAudioRoomEarnings,
  IEarningsConfiguration,
  IPromoCodeEarnings,
  IRaffleDrawEarnings,
  IShopSaleEarnings,
  IVideoEarnings,
  IVideoRoomEarnings,
  getEarningsDataMap,
  useEarningsData,
} from './use-earnings-data';

import styles from './Earnings.module.scss';

const Earnings: React.FC = () => {
  const {
    setDatePeriod,
    isTotalDataLoading,
    milestonesEarnings,
    configuration,
    videosEarnings,
    audioRoomsEarnings,
    videoRoomsEarnings,
    shopSalesEarnings,
    raffleDrawsEarnings,
    promoCodesEarnings,
    isVideoEarningsLoading,
    isAudioRoomsEarningsLoading,
    isVideoRoomsEarningsLoading,
    isShopSalesEarningsLoading,
    isRaffleDrawsEarningsLoading,
    isPromoCodesEarningsLoading,
  } = useEarningsData();

  const transformedEarningsData = useMemo(() => {
    return transformEarningsData(configuration, {
      videosEarnings,
      audioRoomsEarnings,
      videoRoomsEarnings,
      shopSalesEarnings,
      raffleDrawsEarnings,
      promoCodesEarnings,
    });
  }, [
    configuration,
    videosEarnings,
    audioRoomsEarnings,
    shopSalesEarnings,
    raffleDrawsEarnings,
    promoCodesEarnings,
  ]);

  const dataLoading: Record<string, boolean> = {
    videoEarnings: isVideoEarningsLoading,
    audioRoomsEarnings: isAudioRoomsEarningsLoading,
    videoRoomsEarnings: isVideoRoomsEarningsLoading,
    shopSalesEarnings: isShopSalesEarningsLoading,
    raffleDrawsEarnings: isRaffleDrawsEarningsLoading,
    promoCodesEarnings: isPromoCodesEarningsLoading,
  };

  return (
    <div className={styles.container}>
      <div>
        <p>Earnings details</p>
        <PeriodSelect value={3} onValueChange={setDatePeriod} />
      </div>
      <div className={styles.topWidgetsContainer}>
        {milestonesEarnings && (
          <div className={styles.milestonesWidgetContainer}>
            <MilestonesWidget data={milestonesEarnings} />
          </div>
        )}
        <div className={styles.earningsWidgetContainer}>
          <EarningsWidget
            data={transformedEarningsData.totalEarnings}
            loading={isTotalDataLoading}
          />
        </div>
      </div>
      <TabsWidget
        className={styles.tabsWidgetContainer}
        data={transformedEarningsData.tabs}
        loading={dataLoading}
      />
    </div>
  );
};

const formatXAxis = (tickItem: string | Date) => {
  const date = new Date(tickItem);
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
};

interface ITransformEarningsData {
  videosEarnings: { data: IVideoEarnings | null };
  audioRoomsEarnings: { data: IAudioRoomEarnings | null };
  videoRoomsEarnings: { data: IVideoRoomEarnings | null };
  shopSalesEarnings: { data: IShopSaleEarnings | null };
  raffleDrawsEarnings: { data: IRaffleDrawEarnings | null };
  promoCodesEarnings: { data: IPromoCodeEarnings | null };
}

const transformEarningsData = (
  configuration: IEarningsConfiguration | null,
  data: ITransformEarningsData,
) => {
  if (!configuration)
    return {
      totalEarnings: [],
      tabs: [],
    };

  const initialEarningsDataMap = getEarningsDataMap(configuration, data);

  const earningsDataMap = initialEarningsDataMap?.map((earning) =>
    earning?.data ? earning : { key: earning.key, label: earning.label, value: 0, data: null },
  );

  const calculatedTabs: {
    key: string;
    label: string;
    value?: string;
    chart?: React.ReactNode;
    table?: React.ReactNode;
  }[] = [];

  const totalEarnings: { name: string; value: string | number }[] = [];

  earningsDataMap?.forEach((tabData) => {
    if (!tabData.data) {
      calculatedTabs.push({
        key: tabData.key,
        label: tabData.label,
      });
      return;
    }

    const totalTabEarnings = Helpers.calculateSum(tabData.data);

    const groupedTotalTabEarningsByDate = Helpers.groupByDate({
      data: tabData.data,
    });

    const mappedGroupedTotalTabEarningsByDate = groupedTotalTabEarningsByDate
      ? Object.entries(groupedTotalTabEarningsByDate)
          .map(([key, array]) => ({ name: key, value: Helpers.calculateSum(array) }))
          .sort((a, b) => Date.parse(a.name) - Date.parse(b.name))
      : [];

    totalEarnings.push(...mappedGroupedTotalTabEarningsByDate);

    calculatedTabs.push({
      key: tabData.key,
      label: tabData.label,
      value: `$${Helpers.formatNumber(totalTabEarnings)}`,
      chart: mappedGroupedTotalTabEarningsByDate.length ? (
        <CustomLineChart
          height={280}
          data={mappedGroupedTotalTabEarningsByDate}
          CustomToolTip={CustomLineChartNumberToolTip}
          toolTipEndCharacter="$"
          CustomDot={CustomDot}
          xCustomTickFormatter={formatXAxis}
          yCustomTickFormatter={customTickNumberFormatter}
        />
      ) : null,
      table: (
        <TableWidget
          className={tabData.key === 'videoEarnings' ? styles.videoEarningsTable : null}
          data={tabData.tableData}
          title={tabData.tableTitle}
        />
      ),
    });
  });

  return {
    totalEarnings,
    tabs: calculatedTabs,
  };
};

export default Earnings;
