export interface IDatePeriod {
  fromDate: Date | null;
  toDate: Date | null;
}

const PeriodSelectUtils = {
  last7Days: (): IDatePeriod => {
    const currentDate = new Date();
    const toDate = new Date(currentDate);
    const fromDate = new Date(new Date(currentDate).setDate(currentDate.getDate() - 6));
    return { fromDate, toDate };
  },
  last28Days: (): IDatePeriod => {
    const currentDate = new Date();
    const toDate = new Date(currentDate);
    const fromDate = new Date(new Date(currentDate).setDate(currentDate.getDate() - 27));
    return { fromDate, toDate };
  },
  last90Days: (): IDatePeriod => {
    const currentDate = new Date();
    const toDate = new Date(currentDate);
    const fromDate = new Date(new Date(currentDate).setDate(currentDate.getDate() - 89));
    return { fromDate, toDate };
  },
  last5Months: (): IDatePeriod => {
    const currentDate = new Date();
    const toDate = new Date(currentDate);
    currentDate.setMonth(currentDate.getMonth() - 4, 1);
    return { fromDate: currentDate, toDate };
  },
  thisYear: (): IDatePeriod => {
    const currentDate = new Date();
    const toDate = new Date(currentDate);
    const fromDate = new Date(currentDate.getFullYear(), 0, 1);
    return { fromDate, toDate };
  },
  previousYear: (): IDatePeriod => {
    const currentDate = new Date();
    const toDate = new Date(currentDate.getFullYear() - 1, 11, 31);
    const fromDate = new Date(currentDate.getFullYear() - 1, 0, 1);
    return { fromDate, toDate };
  },
  beforeLastYear: (): IDatePeriod => {
    const currentDate = new Date();
    const toDate = new Date(currentDate.getFullYear() - 2, 11, 31);
    const fromDate = new Date(currentDate.getFullYear() - 2, 0, 1);
    return { fromDate, toDate };
  },
  previousMonth: (): IDatePeriod => {
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const toDate = new Date(
      new Date(firstDayOfCurrentMonth).setDate(firstDayOfCurrentMonth.getDate() - 1),
    );
    const fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
    return { fromDate, toDate };
  },
  beforeLastMonth: (): IDatePeriod => {
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1,
    );
    const toDate = new Date(
      new Date(firstDayOfCurrentMonth).setDate(firstDayOfCurrentMonth.getDate() - 1),
    );
    const fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
    return { fromDate, toDate };
  },
  before3Month: (): IDatePeriod => {
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 2,
      1,
    );
    const toDate = new Date(
      new Date(firstDayOfCurrentMonth).setDate(firstDayOfCurrentMonth.getDate() - 1),
    );
    const fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
    return { fromDate, toDate };
  },
  getPreviousYearName: (): number => {
    const currentDate = new Date();
    const previousYear = new Date(currentDate.getFullYear() - 1, 0, 1);
    return previousYear.getFullYear();
  },
  getBeforeLastYearName: (): number => {
    const currentDate = new Date();
    const previousYear = new Date(currentDate.getFullYear() - 2, 0, 1);
    return previousYear.getFullYear();
  },
  getPreviousMonthName: (): string => {
    const currentDate = new Date();
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    return previousMonth.toLocaleString('default', { month: 'long' });
  },
  getBeforeLastMonthName: (): string => {
    const currentDate = new Date();
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
    return previousMonth.toLocaleString('default', { month: 'long' });
  },
  getBefore3MonthName: (): string => {
    const currentDate = new Date();
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
    return previousMonth.toLocaleString('default', { month: 'long' });
  },
};

export default PeriodSelectUtils;
